<template>
  <div>
    <div class="labes">
      <div
        v-for="(items, idx) in comObj.data"
        :key="idx"
        class="tabchild"
        @click="jump(items)"
      >
        <div class="grid-content bg-purple" :style="comObj.style">
          <!--更改按钮-->
          <div class="labround">
            <img :style="comObj.navImg" :src="items.img" alt />
          </div>
          <span>{{items.text}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DiyNav",
  props: ["comObj", "nowComIdx"],
  data() {
    return {
      nowIdx: Number
    };
  },
  mounted() {
    // 初始化数据 若保存过拥有页面加载线上数据反之
  },
  methods: {
    jump(item) {
      this.$router.push(item.link)
    }
  }
};
</script>
<style lang="scss" scoped>
.tabchild {
  width: 20%;
  height: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: center;
  // padding-top: 0.14rem;
  // border: 1px dashed transparent;
}
.tabchild::after {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  content: "";
}
.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  z-index: 100;
  img {
    width: 24px;
    height: 24px;
  }
}
.labels {
  border-bottom: 1px solid #e2e2e2;
}
.labes {
  display: flex;
  justify-content: flex-start;
  padding: 5px 0;
  flex-wrap: wrap;
  // background: #fff;
  font-size: 12px;
}
.grid-content {
  box-sizing: border-box;
  padding: 2px 0;
}
.grid-content span {
  position: relative;
  color: #616161;
}
.labround {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.labround img {
  width: 54px;
  height: 54px;
  border-radius: 50%;
}
</style>
